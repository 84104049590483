import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useToken from '@app/web/src/hooks/utils/useToken';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';

export default function useReportOnlineMember() {
  const { query, pathname } = useRouter();
  const slug = (query.slug || '') as string;
  const token = useToken();

  useEffect(() => {
    async function report(action: string) {
      await call(
        apis.club.reportOnlineMember({
          clubSlug: slug,
          action,
        })
      );
    }
    if (/\/club\/.*\//.test(pathname) && token) {
      // /club/[slug]/{action}/xxx
      const action = pathname.split('/')[3];
      report(action);
    }
  }, [slug, pathname, token]);
}
