import { Box, Theme, useMediaQuery } from '@mui/material';
import { useIaSearchActions } from '@app/web/src/hooks/utils/useIaSearchActions';
import { useIaSearchValues } from '@app/web/src/hooks/utils/useIaSearchValues';
import { SearchBar } from '@front/ui';

const styles = {
  searchBox: {
    px: '20px',
    mt: 1,
    transition: '0.3s',
    '& .search-icon, & .loading-icon': {
      left: 0,
    },
    '& .search-input-wrap': {
      py: '12px !important',
      px: '24px !important',
    },
    '& .search-input': {
      height: '24px',
    },
    '& .clear-button': {
      right: '0 !important',
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
};

export default function TopBarSearch() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { keyword, visible, placeholder } = useIaSearchValues();
  const { setKeyword } = useIaSearchActions();

  if (!visible || mdUp) return null;
  return (
    <Box sx={styles.searchBox}>
      <SearchBar
        placeholder={placeholder || 'Type to Search...'}
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        autoFocus
      />
    </Box>
  );
}
